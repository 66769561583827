import React from 'react';
import { data } from '../../data/data';
import pic1 from '../../assets/p1.png';
import pic2 from '../../assets/p2.png';

const Content = () => {
  return (
    <section id='top'>
      <div className='container'>
        <div className='s-80'></div>
        <h2 className='title c-theme tc'>
          Хто ми і чому "Кадрове Агенство" важливе?
        </h2>
        <div className='s-40'></div>
        <p className='box box-1'>
          "Кадрове Агенство" – це платформа, яка дає можливість молодим і
          амбітним фахівцям реалізувати свій потенціал, працюючи над проектами з
          високою соціальною значущістю. Ми спрямовані на допомогу випускникам
          курсів та університетів знайти своє місце у професійному світі,
          розвиваючи свої навички в реальних робочих умовах.
        </p>
        <div className='s-20'></div>
        <p className='box box-2'>
          У нас є чітке бачення того, як використання технологій може поліпшити
          життя громади. Через нашу платформу ми об'єднуємо талановитих
          фахівців, які прагнуть працювати над важливими проектами, що мають
          потенціал для позитивного впливу на суспільство.
        </p>
        <div className='s-20'></div>
        <p className='box box-3'>
          Ми надаємо нашим учасникам можливість працювати в команді, взаємодіяти
          з іншими професіоналами та здобувати досвід, який стане в нагоді у
          їхньому подальшому професійному житті. Таким чином, кожен учасник не
          лише розвиває свої навички, але й робить вагомий внесок у проекти, які
          змінюють життя громади на краще.
        </p>
        <div className='s-80'></div>
        <h2 className='title c-theme tc w-80 ma'>
          Наш підхід до реалізації соціально значущих проектів
        </h2>
        <div className='s-40'></div>
        <p className='px-5 c-dark tc'>
          <strong>
            Ми створюємо платформу для взаємодії та реалізації проектів,
            спрямованих на вирішення актуальних соціальних проблем. Наш підхід
            базується на залученні талановитих фахівців, які прагнуть зробити
            свій внесок у покращення життя громади.
          </strong>
        </p>
        <div className='s-40'></div>
        <p className='box box-1 mx-5 title-sub'>
          Симуляція реального робочого середовища:
        </p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми створюємо умови, в яких кожен учасник може розширити свої навички у
          програмуванні, дизайні, управлінні проектами та інших ключових
          областях.
        </p>
        <div className='s-20'></div>
        <p className='box box-2 mx-5 title-sub'>Соціально значущі проекти:</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми відібрали ряд важливих тем і проблем, які можна вирішити за
          допомогою технологій. Наші учасники працюють над проектами, що мають
          реальний вплив на суспільство.
        </p>
        <div className='s-20'></div>
        <p className='box box-3 mx-5 title-sub'>Підтримка та наставництво:</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми забезпечуємо підтримку нашим учасникам, надаючи доступ до
          досвідчених менторів та кураторів, які допоможуть з професійним
          розвитком і вирішенням викликів проекту.
        </p>
        <div className='s-20'></div>
        <p className='box box-1 mx-5 title-sub'>Колективний внесок:</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Об'єднуючи знання та зусилля наших учасників, ми працюємо над тим, щоб
          через технології приносити позитивні зміни в суспільство.
        </p>
        <div className='s-40'></div>
      </div>
      <div className='s-80'></div>
      <h2 className='title c-theme tc w-80 ma'>
        Наш вплив на професійний розвиток учасників
      </h2>
      <div className='s-40'></div>
      <p className='px-5 c-dark tc mx-5'>
        <strong>
          Ми горді тим, що можемо надати платформу для тих, хто хоче реалізувати
          свої навички та внести свій вклад у соціально значущі проекти. Ось
          декілька історій успіху від наших учасників, які змогли покращити свої
          професійні навички та знайти своє місце в галузі.
        </strong>
      </p>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-4'>
            <div className='s-40'></div>
            <img
              src={pic1}
              alt='Mariya Zhorzholiani'
              width={360}
              height={360}
              className='w-100 r-24 obfc'
            />
            <div className='s-20'></div>
            <h4 className='title-sub'>Mariya Zhorzholiani</h4>
            <p>UX/UI Designer</p>
            <a href='https://www.linkedin.com/in/maria-zhorzholiani-6023a3205/'>
              <svg
                stroke='currentColor'
                fill='currentColor'
                stroke-width='0'
                viewBox='0 0 24 24'
                height='32'
                width='32'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  d='M20.45175,20.45025 L16.89225,20.45025 L16.89225,14.88075 C16.89225,13.5525 16.86975,11.844 15.04275,11.844 C13.191,11.844 12.90825,13.2915 12.90825,14.7855 L12.90825,20.45025 L9.3525,20.45025 L9.3525,8.997 L12.765,8.997 L12.765,10.563 L12.81375,10.563 C13.2885,9.66225 14.4495,8.71275 16.18125,8.71275 C19.78575,8.71275 20.45175,11.08425 20.45175,14.169 L20.45175,20.45025 Z M5.33925,7.4325 C4.1955,7.4325 3.27375,6.50775 3.27375,5.36775 C3.27375,4.2285 4.1955,3.30375 5.33925,3.30375 C6.47775,3.30375 7.4025,4.2285 7.4025,5.36775 C7.4025,6.50775 6.47775,7.4325 5.33925,7.4325 L5.33925,7.4325 Z M7.11975,20.45025 L3.5565,20.45025 L3.5565,8.997 L7.11975,8.997 L7.11975,20.45025 Z M23.00025,0 L1.0005,0 C0.44775,0 0,0.44775 0,0.99975 L0,22.9995 C0,23.55225 0.44775,24 1.0005,24 L23.00025,24 C23.55225,24 24,23.55225 24,22.9995 L24,0.99975 C24,0.44775 23.55225,0 23.00025,0 L23.00025,0 Z'
                ></path>
              </svg>
            </a>
            <div className='s-20'></div>
            <p>
              На проєкті, я займалася дизайном Ui/Ux для того, щоб створити
              дашборд адміна та зробити за ним мобільну версію. У команді @Roman
              Boreyko, @Ivan Bilobrov для досягнення мети, як створювати
              дашборди та адаптацію його під різні девайси. Я дізналася для
              себе, як це працювати в команді.
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='s-40'></div>
            <img
              src={pic2}
              alt='Roman Boreyko'
              width={360}
              height={360}
              className='w-100 r-24 obfc'
            />
            <div className='s-20'></div>
            <h4 className='title-sub'>Roman Boreyko</h4>
            <p>Project Manager</p>
            <a href='https://www.linkedin.com/in/roman-boreyiko-0193211b9/'>
              <svg
                stroke='currentColor'
                fill='currentColor'
                stroke-width='0'
                viewBox='0 0 24 24'
                height='32'
                width='32'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  d='M20.45175,20.45025 L16.89225,20.45025 L16.89225,14.88075 C16.89225,13.5525 16.86975,11.844 15.04275,11.844 C13.191,11.844 12.90825,13.2915 12.90825,14.7855 L12.90825,20.45025 L9.3525,20.45025 L9.3525,8.997 L12.765,8.997 L12.765,10.563 L12.81375,10.563 C13.2885,9.66225 14.4495,8.71275 16.18125,8.71275 C19.78575,8.71275 20.45175,11.08425 20.45175,14.169 L20.45175,20.45025 Z M5.33925,7.4325 C4.1955,7.4325 3.27375,6.50775 3.27375,5.36775 C3.27375,4.2285 4.1955,3.30375 5.33925,3.30375 C6.47775,3.30375 7.4025,4.2285 7.4025,5.36775 C7.4025,6.50775 6.47775,7.4325 5.33925,7.4325 L5.33925,7.4325 Z M7.11975,20.45025 L3.5565,20.45025 L3.5565,8.997 L7.11975,8.997 L7.11975,20.45025 Z M23.00025,0 L1.0005,0 C0.44775,0 0,0.44775 0,0.99975 L0,22.9995 C0,23.55225 0.44775,24 1.0005,24 L23.00025,24 C23.55225,24 24,23.55225 24,22.9995 L24,0.99975 C24,0.44775 23.55225,0 23.00025,0 L23.00025,0 Z'
                ></path>
              </svg>
            </a>
            <div className='s-20'></div>
            <p>
              Я займався фіксацією прогресу в Трелло, організацією робочого
              процессу, онбордингом нових дизайнерів та розробників, щоб
              створити "Особистий Кабінет". В команді ми реалізовували Бренд
              Бук, Дашборд для адмінки, деякі скріни Ососбістого Кабінета для
              Участника, Проектного Менеджера, Партнера. Я більше дізнався про
              процесс командної розробки на гітхабі.
            </p>
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
      <div className='container'>
        <div className='s-80'></div>
        <h2 className='title c-theme tc w-80 ma'>
          Станьте частиною "Кадрового Агенства" та реалізуйте свій потенціал!
        </h2>
        <div className='s-40'></div>
        <p className='px-5 c-dark tc mx-5'>
          <strong>
            Долучитися до нас – це унікальна можливість працювати над суспільно
            важливими проектами та розвивати свої професійні навички у
            командному середовищі. Ми шукаємо мотивованих людей, які готові
            внести свій вклад у реалізацію важливих проектів і допомогти нам
            створювати позитивні зміни у суспільстві.
          </strong>
        </p>
        <div className='s-40'></div>
        <p className='box box-1 mx-5 title-sub'>Навички та кваліфікація:</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми цінуємо знання у програмуванні, дизайні, управлінні проектами та
          інших сферах, які сприяють успішній реалізації наших проектів.
        </p>
        <div className='s-20'></div>
        <p className='box box-2 mx-5 title-sub'>Менторство та підтримка:</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Ми надаємо підтримку та наставництво нашим учасникам, забезпечуючи їх
          необхідними ресурсами для ефективної роботи та професійного зростання.
        </p>
        <div className='s-20'></div>
        <p className='box box-3 mx-5 title-sub'>
          Робота над реальними проектами:
        </p>
        <div className='s-20'></div>
        <p className='px-5'>
          Учасники матимуть можливість працювати над проектами, що вирішують
          важливі соціальні проблеми та мають реальний вплив на життя громади.
        </p>
        <div className='s-20'></div>
        <p className='box box-1 mx-5 title-sub'>Співтовариство однодумців:</p>
        <div className='s-20'></div>
        <p className='px-5'>
          Приєднавшись до нас, ви зможете спілкуватися та співпрацювати з іншими
          талановитими людьми, які також прагнуть зробити позитивний вплив на
          суспільство.
        </p>
        <div className='s-80'></div>
        <div className='tc'>
          <a href={data.link} target='blank' className='button'>
            Дізнатися більше та приєднатися
          </a>
        </div>
        <div className='s-100'></div>
      </div>
    </section>
  );
};

export { Content };
