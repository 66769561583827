const data = {
  id: 44823262,
  link: 'https://forms.gle/4nA3v4tKXY5Jgns56',
  url: 'https://recruitment-agency.com.ua',
  project: ['КАДРОВЕ АГЕНСТВО', 'RECRUITMENT AGENCY'],
  name: ['Кадрове Агенство', 'Recruitment Agency'],
  email: 'admin@recruitment-agency.com.ua',
  address: [
    'Україна, 65007, Одеська область, місто Одеса, вулиця Старопортофранківська, 107 квартира 21',
    'Ukraine, 65007, Odessa, Odessa region, Staroportofrankivska Street 107 apartment 21',
  ],
};

export { data };
