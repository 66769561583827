import React from 'react';

import { data } from '../data/data';
import logo from '../logo.svg';

const NavPages = () => {
  return (
    <header className='w-100'>
      <div className='container'>
        <header className='flex jcsb aic h-10 py-5'>
          <h1 id='logo'>
            <a href='/' aria-label={data.name[0]}>
              <img
                src={logo}
                width={220}
                height={64}
                alt={data.name[0]}
                style={{ transform: 'translateY(-5px)' }}
              />
            </a>
          </h1>
          <div className='flex jcfe aic mobile'>
            <a href='/' className='mx-4' aria-label={data.name[0]}>
              Головна
            </a>
          </div>
        </header>
      </div>
    </header>
  );
};

export { NavPages };
