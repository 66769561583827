import React from 'react';
import { data } from '../../data/data';

import pic from '../../assets/intro-pic.jpg';

const Intro = () => {
  return (
    <section id='top'>
      <div className='container'>
        <div className='s-40'></div>
        <img
          src={pic}
          alt='feature'
          width={1200}
          height={636}
          className='w-80 h-40 obfc r-8'
        />
        <div className='s-40'></div>
        <p className='title c-theme'>
          Реалізуйте свій потенціал з "Кадровим Агенством"!
        </p>
        <div className='s-20'></div>
        <p className='title-sub'>
          Відкрийте нові можливості для розвитку разом з нами, працюючи над
          суспільно значущими проектами, що змінюють життя громади на краще.
        </p>
        <p>
          Ми пропонуємо унікальну платформу для тих, хто прагне застосувати свої
          знання та навички на реальних проектах із соціальним впливом. Наша
          місія – створити середовище, де кожен зможе зробити свій внесок у
          важливі ініціативи та здобути цінний досвід для подальшого
          професійного розвитку.
        </p>
        <div className='s-10'></div>
        <a href={data.link} target='blank' className='button'>
          Приєднатися
        </a>
        <div className='s-40'></div>
      </div>
    </section>
  );
};

export { Intro };
